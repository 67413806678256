import { Component } from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent extends BaseComponentService{

  public cart = this.cartService.cart
  override ngOnInit() {
  }
}
