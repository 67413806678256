import {Collection} from "./collection.type";
import {VariantModel} from "./variant.model";

export class ProductModel {
  id: number | null = null;
  name: string | null = null;
  status: string | null = null;
  product_type: string | null = null;
  brand: string | null = null;
  price: number | null = null;
  description?: string | null = null;
  options: [] = [];
  images?: {
    thumbnail: string | null;
    hover: string | null;
  };
  thumbnail?: string | null = null;
  hover?: string | null = null;
}


export interface SingleProductModel {
  id: number;
  media: Array<string>;
  attribute_data: Record<string, string>;
  price: number;
  options: Record<string, Array<string>>;
  variants: Array<VariantModel>;
  collections: Array<Collection>;
  brand: {
  id: string;
  name: string;
  description: string;
},
  product_type: string;
  associations: AssociationModel[];
}

export interface AssociationModel {
  id: number;
  type: string;
  product: ProductModel;

}
export interface ProductDetailsAttributes {
  id?: string,
  name: string;
  description?: string;
  array?: Array<string>;
  active?: boolean
}
