<header class="header">
  <!--  <div class="first-header">-->
  <!--    <div class="first-header-container">-->
  <!--      <div>Marketplace</div>-->
  <!--      <div>Help & FAQs</div>-->
  <!--      <div>-->
  <!--        <img src="assets/icons/flag/nepal-icon.svg" alt="no-image"/>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="main__header">
    <div class="container">
      <div class="header__container">
        <button (click)="dropdown.barMenu = true" class="hamburger__menu">
          <i class="icon-bars"></i>
        </button>
        <div class="logo">
          <img src="assets/icons/logo/logo.svg" alt="Logo" />
          <a routerLink="/"></a>
        </div>
        <div class="menu">
          <div class="filter__menu">
          <!--
            <a class="menu__item" [ngClass]="{'active': femaleFlag()}"
           (click)="globalValue.navOption.set(collectionConst.women);">Women</a>
           -->
            <a class="menu__item" [ngClass]="{'active': !femaleFlag()}"
              (click)="globalValue.navOption.set(collectionConst.men);">Men</a>
          </div>
          <!-- TODO: While User are in  Search input -->
          <!-- TODO: Add active class in search -->
          <!-- TODO: hide Overflow from html  -->
          <!-- TODO: Remove active class while key press "ESC" or click on other part rather than search input or clicked on close button on mobile view  -->
          <!-- Search CSS in  Mobile view as well as desktop view is triggered by active class  -->
          <div class="search" [ngClass]="overlayFlag ? 'active' : ''">
            <button class="search__close">
              <i class="icon-close"></i>
            </button>
            <form appOutsideClick [triggerElement]="mobileViewSearchButton" (outsideClick)="overlayFlag = false">
              <input (focus)="overlayFlag = true" [ngModel]="globalValue.searchValue()" (ngModelChange)="globalValue.searchValue.set($event)"
                name="search" type="text" />
              @if(!!globalValue.searchValue()) {
                <button (click)="globalValue.searchValue.set('')" class="search__clear">
                  <i class="icon-close"></i>
                </button>
                }
                <div class="search__result" [ngClass]="{'active': searchItems.length > 0}">
                  <ul>
                    @for (search of searchItems; track $index) {
                      <li (click)="searchClickNavigate(search)">
                        <span class="name">{{search.name}}</span>
<!--                        <span class="result__number">123</span>-->
                      </li>
                    }
                  </ul>
                </div>
            </form>
             <div class="overlay" ></div>
          </div>
          <ul class="special__menu">
            <li #mobileViewSearchButton class="mobile__searchBtn" (click)="overlayFlag = true">
              <a><i class="icon-search"></i>
              </a>
            </li>
            <li class="user">
              <a href="#"><i class="icon-user-regular"></i></a>
              <div class="user__hover__menu">
                <div class="user__header">
                  <div class="signIn">
                    <a routerLink="/user/signin">Sign In</a>
                    <a routerLink="/user/signup">Join</a>
                  </div>
                  <button type="button">
                    <i class="faXmarkSolid"></i>
                  </button>
                </div>
                <ul class="user__hover__list">
                  <li>
                    <a routerLink="/account"><i class="icon icon-user-regular"></i> <span>My Account</span></a>
                  </li>
                  <li>
                    <a routerLink="/saved"><i class="icon icon-shopping-bag"></i> <span>My Orders</span></a>
                  </li>
                  <li>
                    <a href="/return"><i class="icon icon-refund"></i> <span>My Returns</span></a>
                  </li>
                  <li>
                    <a href="/return-info"><i class="icon icon-info"></i> <span>Returns Information</span></a>
                  </li>
                  <li>
                    <a routerLink="contactus/"><i class="icon icon-messages-square"></i> <span>Contact Us</span></a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a><i class="heart-regular"></i></a>
            </li>
            <li class="bag">
              <a>
                <i class="icon-shopping-bag"></i>
              </a>
              <div class="user__hover__menu">
                <div class="user__header">
                  <div class="bag__list">
                    My Bag, <span>{{ cart().count }} item</span>
                  </div>
                  <button type="button">
                    <i class="icon-shopping-bag"></i>
                  </button>
                </div>
                <div class="bag__items">
                  <p class="msg">It’s in the bag - We’ll hold it for an hour</p>
                  <div class="list">
                    @for (cartPro of cart().items; track cartPro.id) {
                      <div class="item">
                        <div class="img__holder">
                          <img [src]="cartPro?.media?.[0]" />
                          <!--                        <app-fake-image-lg></app-fake-image-lg>-->
                        </div>
                        <div class="info">
                               <span class="price">NPR {{cartPro.price}}</span>
                          <p>{{cartPro.name}}</p>
                          <div class="category">
                            @for (option of cartPro.selectedVariant?.value; track $index) {
                              <span>{{ option | uppercase}}</span>
                            }
                            <span>QTY: {{cartPro.quantity}}</span>
                          </div>
                          <button class="icon">
                            <i class="faTrashCanRegular"></i>
                          </button>
                        </div>
                      </div>
                    }

                  </div>
                  <div class="total">
                    <span>Sub-total</span>
                    <span>NPR {{cart().total}}</span>
                  </div>
                  <div class="checkout">
                    <a routerLink="/cart" class="view">VIEW BAG</a>
                    <button class="checkout" routerLink="/checkout">CHECKOUT</button>
                  </div>
                  <!-- <div class="message">
                    <p>Free Delivery Worldwide*</p>
                    <span>More info <a>here</a>.</span>
                  </div> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="main__menu">
    <div class="container">
      <nav (mouseenter)="dropdown.subMenu = true" (mouseleave)="dropdown.subMenu = false">
        @for(sub of  subTwoNavigationList.list;track sub.id) {
          <button
            (click)="filterService.setValue(filterService.filterConstantValue.category, sub.attribute_data.name, true)">{{ sub.attribute_data.name }}</button>
          }
      </nav>
    </div>
    <!--    <div class="hover__menu" [ngClass]="dropdown ? 'active':''">-->
    <!--      <div class="container">-->
    <!--        <div class="hover__menu__container menu__styles__two">-->
    <!--          <div class="menu__list">-->
    <!--            <h2 class="title">New Products</h2>-->
    <!--            <ul>-->
    <!--              <li><a href="/sale/demo"><span>View all</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Clothing</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Shoes</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>T-Shirts & Vests</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Coats & Jackets</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Jumpers</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Jeans & Trousers</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Sportswear</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Accessories</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Face + Body</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Underwear</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span>Loungewear</span></a></li>-->
    <!--              <li class="special"><a href="#">Join CELAVI Premier</a></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="menu__list icon__sm">-->
    <!--            <h2 class="title">Brand Drops</h2>-->
    <!--            <ul>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">CELAVI DESIGN</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">adidas</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">New Balance</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Nike</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Carhartt WIP</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Topman</span></a></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="menu__list icon__md">-->
    <!--            <h2 class="title">Top Saved</h2>-->
    <!--            <ul>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Clothing</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Brands</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Sportswear</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImg.png" /></span><span class="name">Trainers</span></a></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="menu__list icon__lg">-->
    <!--            <h2 class="title">New Edits</h2>-->
    <!--            <ul>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImgLg.png" /></span><span class="name">Clothing</span></a></li>-->
    <!--              <li><a href="/sale/demo"><span class="icon"><img src="assets/media/fakeImgLg.png" /></span><span class="name">Brands</span></a></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>


  <!--  <div class="header__cta">-->
  <!--    <div class="container">-->
  <!--      <div class="header__cta__container">-->
  <!--        <a (click)="navigateToProductPage(collectionConst.women)" class="header__cta__btn">Women</a>-->
  <!--        <p>-->
  <!--          PSST! NEW HERE? Get 15% off almost everything!* <br />-->
  <!--          With code: HIFRIEND-->
  <!--        </p>-->
  <!--        <a (click)="navigateToProductPage(collectionConst.men)" href="#" class="header__cta__btn">men</a>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->


  <!--  mobile view-->
  <div class="mobile__menu__container" [ngClass]="{'active': dropdown.barMenu}">
    <div class="mobile__menu">
      <button class="menu__close" (click)="dropdown.barMenu = false">
        <i class="icon-close"></i>
      </button>
      <div class="mobile__menu__box">
        <!--        sub__menu__active-->
        <div class="menu__container ">
          <div class="mobile__main__menu">
            <div class="filter__menu">
<!--              <button class="menu__item active" [ngClass]="{'active': femaleFlag()}"-->
<!--                (click)="globalValue.navOption.set(collectionConst.women)">Women</button>-->
              <button class="menu__item" [ngClass]="{'active': !femaleFlag()}"
                (click)="globalValue.navOption.set(collectionConst.men)">Men</button>
            </div>
            <div appOutsideClick (outsideClick)="dropdown.subMenu = false" class="menu__normal">
              <a routerLink="/" class="item link">Home</a>
              @for(sub of  subTwoNavigationList.list;track sub.id) {
                <button
                  (click)="filterService.setValue(filterService.filterConstantValue.category, sub.attribute_data.name, true); dropdown.barMenu = false"
                  class="item">{{ sub.attribute_data.name }}</button>
                }
            </div>
          </div>
          <!--          <div class="sub__menu">-->
          <!--            <div class="filter__sub__menu">-->
          <!--              <button class="back__btn">-->
          <!--                <span>&larr;</span> &lt;!&ndash; Replacing FaArrowLeftLongSolid icon with a placeholder &ndash;&gt;-->
          <!--              </button>-->
          <!--              <h3>Sale</h3>-->
          <!--            </div>-->
          <!--            <div class="sub__menu__list">-->
          <!--              <div class="menu__list">-->
          <!--                <h2 class="title">New Products</h2>-->
          <!--                <ul>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>View All</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Clothing</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Shoes</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>T-Shirts & Vests</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Coats & Jackets</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Jumpers</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Jeans & Trousers</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Sportswear</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Accessories</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Face + Body</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Underwear</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span>Loungewear</span></a></li>-->
          <!--                  <li class="special"><a href="#"><span class="icon">[Icon]</span><span>Join CELAVI Premier</span></a></li>-->
          <!--                </ul>-->
          <!--              </div>-->
          <!--              <div class="menu__list icon__sm">-->
          <!--                <h2 class="title">Brand Drops</h2>-->
          <!--                <ul>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">CELAVI DESIGN</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">adidas</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">New Balance</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Nike</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Carhartt WIP</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Topman</span></a></li>-->
          <!--                </ul>-->
          <!--              </div>-->
          <!--              <div class="menu__list icon__md">-->
          <!--                <h2 class="title">Top Saved</h2>-->
          <!--                <ul>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Clothing</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Brands</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Sportswear</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Trainers</span></a></li>-->
          <!--                </ul>-->
          <!--              </div>-->
          <!--              <div class="menu__list icon__lg">-->
          <!--                <h2 class="title">New Edits</h2>-->
          <!--                <ul>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Clothing</span></a></li>-->
          <!--                  <li><a href="/sale/demo"><span class="icon">[Icon]</span><span class="name">Brands</span></a></li>-->
          <!--                </ul>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>

</header>
