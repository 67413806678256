export class ApiConstant {
  public static AUTH = 'auth'
  public static LOGIN = 'login';
  public static LOG_OUT: string = 'logout';
  public static REGISTER = 'register';
  public static CHANGE_PASSWORD:string = 'change-password';
  public static USER:string = 'users';
  public static USER_MANAGEMENT: string = 'user-management'



  public static GOOGLE = 'google';
  public static COLLECTION = 'collections';
  public static PRODUCTS = 'products';
  public static BRANDS = 'brands';
  public static COLLECTION_GROUP = 'collection-groups';
  public static LOCALSTORAGE = {
    user: 'USER',
    token: 'TOKEN',
    gender: 'GENDER'
  };
  public static UP_SELLING = 'up-sell';
  public static CROSS_SELLING = 'cross-sell';
}
