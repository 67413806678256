<div class="item">
  <div class="img__holder">
<!--      <span class="discountRate">-29%</span>-->
<!--      <span class="color__option">More Colours</span>-->
<!--      <span class="sale__process">Selling fast</span>-->
      <span class="like">
        <i class="icons heart-regular"></i>
        <i class="icons icon-heart"></i>

      </span>
      <img [src]="product?.images?.thumbnail || product?.thumbnail" #img
           (error)="img.src = 'assets/media/product-img.png'" alt="Product Image"> <!-- Placeholder for ProductImage -->
  </div>
  <div class="content">
      <p class="info">{{product?.name | titlecase}}</p>
      <div class="price">
          <span>NPR {{product?.price}}</span>
<!--          <span>£79.99</span>-->
      </div>
  </div>
  <a [routerLink]="['/','detail',product?.id]"></a>
</div>
