import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {ApiConstant} from "../../shared/constants";
import {ProductDetailsAttributes, SelectedVariant, SingleProductModel} from "../../shared/model";
import {AssociationModel} from "../../shared/model/product.model";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AddCartService} from "../../shared/services/component-service/add-cart/add-cart.service";


@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule
  ],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductDetailComponent extends BaseComponentService{
  private route: ActivatedRoute = inject(ActivatedRoute);
  private elementRef: ElementRef = inject(ElementRef);

  protected cartForm: FormGroup<{[key:string]: FormControl}> = new FormGroup<{[p: string]: FormControl}>({});

  protected products: SingleProductModel | null = null;
  protected productOption:ProductDetailsAttributes[]  = [];
  protected productDetail:ProductDetailsAttributes[]  = [];
  public crossSellProduct: AssociationModel[] = [];
  public upSellProduct: AssociationModel[] = [];

  private variantDict: Record<number, SelectedVariant> = {}

  private exceptDetailArray: Array<string> = ['name'];









  override ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.getProductIdFromParams();
    }
  }

  private resetAll() {
   this.productOption = [];
   this.productDetail = [];
   this.crossSellProduct = [];
   this.upSellProduct = [];
   this.elementRef.nativeElement.scrollTop = 0;
  }


  private getProductIdFromParams() {
    this.subscriptionArray = this.route.params.subscribe({
      next: (params) => {
        this.resetAll();
        this.getProductById(params['id']);
      }
    })
  }


  private getProductById(id: Number) {
    this.loader.show();
    this.subscriptionArray = this.request.getRequest<SingleProductModel>(`${ApiConstant.PRODUCTS}/${id}`,{
      ...this.request.defaultConfiguration,
      auth: false,
      domain: this.request.secondBaseUrl
    }).subscribe({
      next: (res: any) => {
        this.loader.hide();
        if(res){
          this.products = res.data.payload;
          this.setProductDetails();
        }
      }
    })
  }

  private setProductDetails() {
    this.productOption = Object.keys(this.products?.options || {}).map((key) => {
      this.cartForm.addControl(key , new FormControl(this.products?.options[key][0], [Validators.required]));
      return {
        name: key,
        array: this.products?.options[key]
      }
    });
    Object.keys(this.products?.attribute_data || {}).forEach((key) => {
      if(!this.exceptDetailArray.includes(key)) {
        this.productDetail.push({
          id: key,
          name: key.split("-").join(' '),
          description: this.products?.attribute_data[key],
          active: false
        })
      }
    })
    this.productDetail.push({
      id: this.products?.brand.id,
      name: 'brand',
      description: `<p>${this.products?.brand.name}</p>${this.products?.brand.description}`,
      active: false
    })
    this.productDetail[0].active = true;

    this.products?.associations.forEach((data) => {
      if(ApiConstant.UP_SELLING.toLowerCase() == data.type.toLowerCase()) {
        this.upSellProduct.push(data);
      } else {
        this.crossSellProduct.push(data);
      }
    })

    this.products?.variants.forEach((variant)=> {
      this.variantDict[variant.id] = {
        value: variant.options.map(data => data.value),
        variant: variant
      }
    })
  }


  protected openDetailDropdown(id: string | null = null){
    this.productDetail.forEach((data) => {
      if(data.id === id) {
        data.active = !data.active;
      } else {
        data.active = false;
      }
    })
  }


  protected addToCart() {
    console.log(this.cartForm)
    const form = Object.values(this.cartForm.getRawValue());
    let variantId: number | null = null;
    Object.keys(this.variantDict).forEach((key: string) => {
      let existFlag = this.variantDict[+key].value.every(element => form.includes(element));
      if(existFlag) variantId = +key;
    })

    if(variantId) {
      console.log(this.variantDict[variantId])
      this.cartService
        .add(AddCartService.cartName,
          this.products as SingleProductModel,
          this.variantDict[variantId]);
    }

  }

  protected addToFavorite() {

  }

}
