import {computed, inject, Injectable, PLATFORM_ID, Signal, signal, WritableSignal} from '@angular/core';
import {CartModel, ProductModel, SelectedVariant, SingleProductModel} from "../../../model";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AddCartService {
  public cart$:WritableSignal<CartModel[]> = signal([]);
  public favorite$:WritableSignal<CartModel[]> = signal([]);
  protected platformId = inject(PLATFORM_ID);
  public static cartName:string = 'cart';
  public static favoriteName: string = 'favorite';

  public cart: Signal<{count: number, items: CartModel[], total: number}> = computed(() => {
    return {
      count: this.cart$().length,
      items: this.cart$(),
      total: this.cart$().map(data => (data?.price || 0) * (data?.quantity || 1)).reduce((a,c) => {
        return a + c;
      },0)
    }
  })

  constructor() {
    if(isPlatformBrowser(this.platformId)) {
      this.init();
    }
  }

  init(){
    const cart = localStorage.getItem(AddCartService.cartName);
    const favorite = localStorage.getItem(AddCartService.favoriteName);
    if(cart) {
      let arr = JSON.parse(cart);
      console.log(arr)
      this.cart$.set(arr);
    }
    if(favorite){
      let arr = JSON.parse(favorite);
      this.favorite$.set(arr);
    }
  }

  public add(name: string, product: SingleProductModel | ProductModel, selected_Variant: SelectedVariant | null = null){
    let parse = localStorage.getItem(name);
    let itemArray: Array<CartModel> = parse ? JSON.parse(parse) : [];
    let cart: CartModel = new CartModel(product);
    if(selected_Variant) {
      cart.selectedVariant = selected_Variant;
    }

    if(name === AddCartService.cartName) {
      let ifExist = itemArray.find((pro:CartModel) => pro.id === product.id &&
        selected_Variant?.variant.id === pro.selectedVariant?.variant.id);
      if(ifExist) ifExist.quantity++;
      else itemArray.push({...cart, quantity: 1});
    }

    if(name === AddCartService.cartName){
      this.cart$.set(itemArray);
    } else {
      this.favorite$.set(itemArray);
    }
    localStorage.setItem(name, JSON.stringify(itemArray));
  }

  decrease(name: string,product: ProductModel, quantity: number = 1){
    let parse = localStorage.getItem(name);
    let itemArray = parse ? JSON.parse(parse) : [];
    let ifExist = itemArray.find((pro:ProductModel) => pro.id === product.id)
    if(ifExist) ifExist.num = quantity
    this.cart$.set(itemArray);
    localStorage.setItem(name, JSON.stringify(itemArray));
  }


  remove(name: string, id: number){
    let parse = localStorage.getItem(name);
    let itemArray = parse ? JSON.parse(parse) : [];
    if(name === AddCartService.cartName){
      itemArray = this.cart$().filter(pro => pro.id !== id)
      this.cart$.set(itemArray);
      localStorage.setItem(name, JSON.stringify(this.cart$()));
    } else {
      itemArray = this.favorite$().filter(pro => pro.id !== id)
      this.favorite$.set(itemArray);
      localStorage.setItem(name, JSON.stringify(this.favorite$()));
    }
  }



  removeUser(key: string) {
    localStorage.removeItem(key);
  }

  checkWhereAdded(name: string , id: number) {
    let parse = localStorage.getItem(name);
    let itemArray = parse ? JSON.parse(parse) : [];
    let ifExist = itemArray.find((pro:ProductModel) => pro.id === id);
    return !!ifExist;
  }


  clearAllCart(name: string) {
    localStorage.removeItem(name);
    this.init();
    if(AddCartService.cartName === name) {
      this.cart$.set([]);
    } else {
      this.favorite$.set([]);
    }

  }
}
