import {Component, inject} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {Form, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-account-password',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './account-password.component.html',
  styleUrl: './account-password.component.scss'
})
export class AccountPasswordComponent extends BaseComponentService {
  protected passwordForm: FormGroup<{current_password: FormControl<string | null>, password: FormControl<string | null>}>;
  private formBuilder: FormBuilder = inject(FormBuilder);

  constructor() {
    super();
    this.passwordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      password:  ['', [Validators.required]]
    })
  }


  override ngOnInit() {
  }


  protected savePassword() {
    if(this.passwordForm.invalid) return;
    const payload = {
      ...this.passwordForm.getRawValue(),
      password_confirmation: this.passwordForm.getRawValue().password
    };
    this.subscriptionArray = this.request
      .postRequest(``, payload)
      .subscribe({
        next: (res) => {
          if(res) {
            this.auth.signOut();
          }

        }
      })
  }

}
