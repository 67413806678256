<main>
  <div class="container-xs">
    <h2>AURA POLICY</h2>
    <div class="main__container">
      <div class="item">
        <h3 class="item__title">1. Delivery Policy</h3>
        <div class="item__list">
          <div class="content">
            <h4 class="sub__title">
              · Processing Time</h4>
            <p>Orders are processed within 1-3 business days after payment confirmation. During peak seasons or
              promotional sales, processing may take an additional 1-2 </p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Shipping Methods and Timeframes</h4>
            <p>Standard shipping: 5-7 business days </p>
            <p>Express shipping: 2-3 days </p>
            <p>Overnight shipping: 1business day(available in select locations). </p>
            <p>Delivery timeframes may vary based on the destination and courier </p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Shipping Fees</h4>
            <p>Shipping costs are calculated at checkout and vary based on the selected shipping method, destination,
              and order weight.</p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Failed Deliveries</h4>
            <p>If a delivery fails due to an incorrect address or absence of the recipient, reshipping fees may apply.
            </p>
          </div>
        </div>
      </div>
      <div class="item">
        <h3 class="item__title">2. Payment Policy</h3>
        <div class="item__list">
          <div class="content">
            <h4 class="sub__title">
              · Accepted Payment Policy Methods</h4>
            <p>Online wallets (esewa) </p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Failed Transactions</h4>
            <p>
              In the case of a failed transaction, please check your payment method details or contact your bank. For
              unresolved issues, reach out to our support team. </p>
          </div>
        </div>
      </div>
      <div class="item">
        <h3 class="item__title">3. Return Policy</h3>
        <div class="item__list">
          <div class="content">
            <h4 class="sub__title">
              · Eligibility for returns</h4>
            <p>Items can be returned within 48 hours of delivery, provided they are: </p>

              <p>Unworn, unwashed, and in their original condition</p>
              <p>Retained with original tags and packaging</p>

          </div>
          <div class="content">
            <h4 class="sub__title">· Refunds</h4>
            <p>
              Refunds will be processed within 7-10 business days after the returned item is inspected and approved.
              Refunds will be issued to the original payment method. </p>
          </div>
        </div>
      </div>
      <div class="item">
        <h3 class="item__title">4. Exchange Policy</h3>
        <div class="item__list">
          <div class="content">
            <h4 class="sub__title">
              · Eligibility for exchange</h4>
            <p>Exchanged are allowed for size or color changes only. Items must be: </p>
            <p>Unworn, unwashed, and in their original condition</p>
            <p>Retained with original tags and packaging</p>

          </div>
          <div class="content">
            <h4 class="sub__title">· Exchange process</h4>
            <p>
              To initiate an exchange: </p>
            <p>1. Log in to your account and go to the "Orders" section.</p>
            <p>2. Select the item(s) for exchange and choose the desired size/color.</p>
            <p>3. Follow the instructions to ship the item(s) back to our warehouse</p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Processing Time:</h4>
            <p>
              Once the returned item is received and inspected, the replacement item will be shipped within 3-5 business
              days. If the requested size/color is unavailable, a refund will be provided </p>
          </div>
          <div class="content">
            <h4 class="sub__title">· Special Cases</h4>
            <p>
              If you receive a damaged or incorrect item, please contact our support team within 48 hours of delivery
              for an expedited exchange or refund</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-user-term />
</main>
