import {ProductModel, SingleProductModel} from "./product.model";
import {SelectedVariant, VariantModel} from "./variant.model";

export class CartModel {
  id: number | null;
  name: string | null;
  media: Array<string> | null;
  price: number | null;
  description: string | null;
  quantity: number = 0;
  variant?: Array<VariantModel> | null;
  options?: Record<string, string>[] | null;
  selectedVariant?: SelectedVariant | null;

  constructor(product: SingleProductModel |  ProductModel ) {
    this.id = product.id;
    if(product instanceof ProductModel) {
      this.name = product?.name || null;
      this.media = product?.thumbnail ? [product.thumbnail] : null;
      this.price = product?.price || null;
      this.description = product?.description || null;
    } else {
      this.name = product?.attribute_data['name'] || null;
      this.media = product?.media || null;
      this.price = product?.price || null;
      this.description = product.attribute_data['description']
      this.variant = product?.variants || null;
    }
  }
}
