<div class="checkout-container">
  <div class="delivery-message">
    <p>Can we tempt you? Spend another £188.01 to qualify for FREE Standard Delivery to Nepal.</p>
  </div>
  <div class="container">
    <div class="checkout-details">
      <div class="checkout">
        <div class="checkout-head">
          <h2 class="title">MY BAG</h2>
          <span>Items are reserved for 60 minutes</span>
        </div>
        <div class="checkout-body">
          <!-- Item 1 -->
          @for(cartItem of cart().items; track cartItem.id) {
            <div class="item">
              <div class="product-img">
                <img [src]="cartItem?.media?.[0]" alt="Product Image" />
              </div>
              <div class="product-content">
                <span class="price">NPR {{cartItem.price}}</span>
                <p class="info" [innerHTML]="cartItem.description"></p>
                <div class="category">
                  <span>BLACK | S</span>
                  <span><span class="qty">Qty</span> <span>{{cartItem.quantity}}</span></span>
                </div>
                <button><i class="icon heart-regular"> </i> Save for later</button>
              </div>
              <div class="btn">
                <button><i class="icon icon-close"></i></button>
              </div>
            </div>
          }

        </div>
        <div class="checkout-tail">
          <h2 class="sub-title">Sub-total</h2>
          <span>NPR {{cart().total}}</span>
        </div>
        <div class="extra">
          A little something extra?
        </div>
        <div class="delivery">
          <div class="icon-holder">
            <img src="assets/icons/truck.svg" width="32" height="32" alt="Truck" />
          </div>
          <div class="content">
            <h3>FREE* STANDARD DELIVERY</h3>
            <p>Faster delivery options available to most countries.</p>
            <a href="#">More info</a>
          </div>
        </div>
      </div>
      <div class="cart-container">
        <h2 class="title">Total</h2>
        <div class="total">
          <div class="price">
            <h3 class="sub-title">Sub-total</h3>
            <span>NPR {{cart().total}}</span>
          </div>
          <div class="delivery">
            <h3 class="sub-title">Delivery</h3>
            <select>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
            </select>
          </div>
        </div>
        <button>CHECKOUT</button>
        <div class="accept">
          <h2 class="title">WE ACCEPT:</h2>
          <div class="cards">
            <span>Icon</span>
            <span>Icon</span>
            <span>Icon</span>
            <span>Icon</span>
          </div>
          <p>Got a discount code? Add it in the next step.</p>
        </div>
      </div>
    </div>
    <div class="saved-info">
      <div class="content">
        <h2>Wondering where your items have gone?</h2>
        <hr />
        <p>No worries – you'll find them in your Saved Items.</p>
        <a>VIEW ALL SAVED ITEMS</a>
      </div>
    </div>
  </div>
</div>
