export function getNestedObjectValue<T>(keyName: string | null | undefined, obj: T) {
  if(!keyName || !obj) return null;
  if(keyName.includes('.')) {
    const nestedKey = keyName.split('.');
    let value:any = obj;
    for(let i of nestedKey) {
      value = value[i as keyof T] || null
    }
    return value;
  } else {
    return obj[keyName as keyof T];
  }
}
