<div class="my__details-container">
  <div class="head__component">
    <h2 class="title">Change password</h2>
    <p>
      Feel free to edit any of your details below so your CELAVI account is totally up to date. (* Indicates a required
      field).
    </p>
  </div>
  <form class="custom__form" [formGroup]="passwordForm" (ngSubmit)="savePassword()">
    <div class="input__box">
      <label>Current Password*:</label>
      <input formControlName="current_password" type="password" />
    </div>
    <div class="input__box">
      <label>New Password*:</label>
      <input formControlName="password" type="password" />
      <span>Must be 10 or more characters</span>
    </div>
    <input  type="submit" value="Save Password" />
  </form>
</div>
