import { Component } from '@angular/core';
import {UserTermComponent} from "../../components";
@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [
    UserTermComponent
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

}
