import {CommonModule, isPlatformBrowser} from '@angular/common';
import {Component, inject} from '@angular/core';
import {OneProductComponent} from "../one-product/one-product.component";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ActivatedRoute} from "@angular/router";
import {ApiConstant} from "../../shared/constants";
import {ProductModel} from "../../shared/model";
import {DynamicFilterModel} from "../../shared/model/products-filter.model";
import {FormsModule} from "@angular/forms";
import {ListFilterPipe} from "../../shared/Pipe/filter-list.pipe";
import {OutsideClickDirective} from "../../shared/Directive/outside-click.directive";

@Component({
  selector: 'app-product-filter',
  standalone: true,
  imports: [
    CommonModule,
    OneProductComponent,
    FormsModule,
    ListFilterPipe,
    OutsideClickDirective
  ],
  templateUrl: './product-filter.component.html',
  styleUrl: './product-filter.component.scss'
})
export class ProductFilterComponent extends BaseComponentService{
  protected productList: Array<ProductModel> = [];
  protected filterListFromApi: DynamicFilterModel = {
    key: [],
    dict: {}
  };
  private route: ActivatedRoute = inject(ActivatedRoute);

  constructor() {
    super();
  }
  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.getDataFromQueryParams();
    }
  }

  private getDataFromQueryParams() {
    this.subscriptionArray = this.route.queryParams.subscribe({
      next: (data) => {
        if(Object.keys(data).length > 0 && !(Object.keys(this.filterService.filter()).length > 0)) {
          this.filterService.filter.set(data);
        }
        this.getProduct()
      }
    })
  }


  private initFilter(filterList: Record<string, {[key: string]: string}> | any) {
    this.filterListFromApi.key = [];
    const selectedOption: Array<string> = [] ;
    Object.values(this.filterService.filter()).forEach(value => {
      if(!value) return;
      if(value.includes(',')) {
        value.split(',').forEach(nestedValue => selectedOption.push(nestedValue));
      }
      else {
        selectedOption.push(value);
      }
    });
    // this.router.navigate([], {queryParams: this.filterService.filter()})
    Object.keys(filterList).forEach(key => {
      let modifiedKey = key;
      if(modifiedKey.includes('.')){
        modifiedKey = modifiedKey.split('.')[1];
      }
      if(modifiedKey.includes('_')) {
        modifiedKey = modifiedKey.split('_').join(' ');
      }

      this.filterListFromApi.key.push(modifiedKey);

      this.filterListFromApi.dict[modifiedKey] = {
        option: Object.keys(filterList[key]).map((data,index) => {
          if(Array.isArray(filterList[key])){
            return {
              name: filterList[key][data],
              count: "",
              selected: selectedOption.includes(filterList[key][data]) || false
            }
          } else {
            return {
              name: data,
              count: filterList[key][data],
              selected: selectedOption.includes(data) || false
            }
          }
        }),
        name: key,
        selectAll: false,
        selectCount: 0,
        active: false,
        searchValue: ''
      }

      this.filterListFromApi.dict[modifiedKey].selectCount = this.filterListFromApi.dict[modifiedKey]
        .option.filter(data => data.selected).length;
    })
  }

  protected clearSpecificFilter(key: string){
    this.filterListFromApi.dict[key].option = this.filterListFromApi.dict[key]
      .option
      .map(op => ({...op,selected: false}));
    this.filterListFromApi.dict[key].selectCount = this.filterListFromApi.dict[key].option
      .filter(data => data.selected).length;
    this.parseDropdownValue(key);
  }

  private getProduct() {
    this.loader.show();
    const query = encodeURI(this.filterService.initPayload());
    this.request.getRequest(`${ApiConstant.PRODUCTS}?${query}`,
      {...this.request.defaultConfiguration,
        auth: false,
        domain: this.request.secondBaseUrl})
      .subscribe({
        next: (res) => {
          this.loader.hide();
          this.productList = res.data;
          this.initFilter(res.facets)
        }
      })

  }


  protected selectAllCategory(key: string) {
    this.filterListFromApi.dict[key].option = this.filterListFromApi.dict[key]
      .option
      .map(op => ({...op,selected: true}));
    this.filterListFromApi.dict[key].selectCount = this.filterListFromApi.dict[key].option
      .filter(data => data.selected).length;
    this.parseDropdownValue(key);
  }

  protected openFilter(key: string) {
    this.closeAllFilter(key);
    this.filterListFromApi.dict[key].active = !this.filterListFromApi.dict[key].active;
  }

  protected onChange(key: string, index: number) {
    this.filterListFromApi.dict[key].option[index].selected = !this.filterListFromApi.dict[key]
      .option[index].selected;
    this.filterListFromApi.dict[key].selectCount = this.filterListFromApi.dict[key].option
      .filter(data => data.selected).length;
    this.parseDropdownValue(key);

  }

  protected parseDropdownValue(key: string) {
    let filter = this.filterService.filter();
    let value = this.filterListFromApi.dict[key].option
      .filter(data => data.selected)
      .map(data => data.name).join(',');
    if(value) {
      filter = {
        ...filter,
        [key]: value
      }
    } else{
      console.log(filter, value)
      if (filter.hasOwnProperty(key)) delete filter[key];
    }
    this.filterService.filter.set(filter);
    this.getProduct();
  }

  protected closeAllFilter(except?: string) {
     this.filterListFromApi.key.forEach((key) => {
       if(except && except === key) return;
       this.filterListFromApi.dict[key].searchValue = '';
       this.filterListFromApi.dict[key].active = false;
     })
   }


  override ngOnDestroy() {
    super.ngOnDestroy();
    this.filterService.filter.set({});
  }


}
