import {inject, Injectable, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {TokenType, UserType} from "../../model";
import {SecurityService} from "../security/security.service";
import {ApiConstant} from "../../constants";
import {isPlatformBrowser} from "@angular/common";
import {Router} from "@angular/router";
import {take} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public isAuthenticated:  WritableSignal<boolean> = signal(false);
  public user: WritableSignal<UserType | null> = signal(null);
  public token: WritableSignal<TokenType | null> = signal(null);
  private http: HttpClient = inject(HttpClient);
  private router: Router = inject(Router);
  private baseUrl: string = `${environment.BASE_URL}/${environment.PUBLIC_API_VERSION}`;

  constructor() {
    if(isPlatformBrowser(PLATFORM_ID)) {
      this.autoSignIn();
    }
  }


  public autoSignIn() {
    const user = SecurityService.getFromLocalStorage(ApiConstant.LOCALSTORAGE.user);
    const token = SecurityService.getFromLocalStorage(ApiConstant.LOCALSTORAGE.token);
    if(!!user && !!token) {
      this.isAuthenticated.set(true);
      this.token.set(token);
      this.user.set(user);
    }
  }
  public signIn(token: TokenType, user: UserType) {
    this.isAuthenticated.set(true);
    this.user.set(user);
    this.token.set(token);
    SecurityService.saveToLocalStorage(ApiConstant.LOCALSTORAGE.user,user);
    SecurityService.saveToLocalStorage(ApiConstant.LOCALSTORAGE.token,token);
  }

  public logOut() {
    this.http
      .post(`${this.baseUrl}/${ApiConstant.AUTH}/${ApiConstant.LOG_OUT}`, null)
      .pipe(take(1))
      .subscribe();
  }

  public signOut() {
    this.user.set(null);
    this.token.set(null);
    this.isAuthenticated.set(false);
    this.logOut();
    SecurityService.clearLocalStorage();
    this.router.navigate(['/']);
  }


}
