import {getNestedObjectValue} from "../function";

export class DataIndex<T = any>{
   public dict: Record<string | number, T>;
   public list: T[];
    constructor(data: T[] = [], keyName:string = 'id') {
      this.list = data;
      this.dict =  this.mapDict(data, keyName);
    }

    private mapDict(data: T[], keyName: string) {
      let obj: Record<keyof T | any, T> = {};
      if(keyName.includes('.'))
      for(let item of data) {
        let key = getNestedObjectValue(keyName,item);
        obj[key as keyof T] = item;
      }
      return obj;
    }
}
