import { Component, Input } from '@angular/core';

interface VideoPlayerData {
  thumbnail: string;
  videoSrc: string;
  isLandscape: boolean;
}

@Component({
  selector: 'app-dynamic-video-player',
  standalone: true,
  imports: [],
  templateUrl: './dynamic-video-player.component.html',
  styleUrl: './dynamic-video-player.component.scss'
})
export class DynamicVideoPlayerComponent {

 @Input('VideoPlayerData') VideoPlayerData?: VideoPlayerData;

}
