import {Component, inject} from '@angular/core';
import {
  ProductComponent,
  ProductCtaComponent,
  PromoBannerAppComponent,
  PromoBannerComponent,
  PromoBannerTwoComponent,
  VideoPlayerComponent
} from "../../components";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {BrandsComponent} from "../../components/brands/brands.component";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ApiConstant} from "../../shared/constants";
import {Collection, ProductModel} from "../../shared/model";
import {CollectionService} from "../../shared/services/component-service/collection/collection.service";
import {DataIndex} from "../../shared/class/data-index.class";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    PromoBannerComponent,
    VideoPlayerComponent,
    CommonModule,
    ProductComponent,
    PromoBannerTwoComponent,
    ProductCtaComponent,
    PromoBannerAppComponent,
    BrandsComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent extends BaseComponentService{
  protected collectionList: DataIndex = new DataIndex<Collection>();
  protected brandsProductList: ProductModel[] = Array.from({ length: 2 });
  protected productList = Array.from({ length: 4 });
  public collectionService: CollectionService = inject(CollectionService);
  protected videoPlayerData = {
    thumbnail:"",
    videoSrc:"assets/media/homepage__video.MP4"
  }

   ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      this.getCollectionById();
    }
  }

  private getCollectionById() {
    let menWomens = 2;
    this.subscriptionArray = this.request
      .getRequest(`${ApiConstant.COLLECTION_GROUP}/${menWomens}/${ApiConstant.COLLECTION}?include=products`,{
      ...this.request.defaultConfiguration,
        auth: false,
      domain: this.request.secondBaseUrl
    }).subscribe({
      next: (res) => {
        const collections = this.collectionService.buildCollectionTree(res.data);
        this.collectionList = new DataIndex<Collection>(collections, 'attribute_data.name');
      }
    })
  }

  private getProductsByBrand() {
    let menWomens = 2;
    this.subscriptionArray = this.request
      .getRequest(`${ApiConstant.COLLECTION_GROUP}/${menWomens}/${ApiConstant.COLLECTION}`,{
        ...this.request.defaultConfiguration,
        auth: false,
        domain: this.request.secondBaseUrl
      }).subscribe({
        next: (res) => {
          this.brandsProductList = res.data;
        }
      })
  }

}
