import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {CustomProductFilter} from "../../model/products-filter.model";
import {Router} from "@angular/router";
import {GlobalValueService} from "../global-value-service/global-value.service";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public filter: WritableSignal<CustomProductFilter> = signal({});
  private globalValue: GlobalValueService = inject(GlobalValueService);
  private router: Router = inject(Router);
  public filterConstantValue = {
    gender: 'gender',
    category: 'category',
    sort: 'sort',
    search: 'searchTerm',
    brand: 'brand'
  }

  constructor() { }

  public setValue(filterKey: string, value: string, redirect: boolean = false) {
    let filter = this.filter();
    filter = {
      ...filter,
      [filterKey]: value,
    }
    this.filter.set(filter);
    console.log(this.filter(),"[][][][][]]-=-=-==-=")
    if(redirect) {
      this.addQueryParamsAndRedirect(this.filter());
    }
  }


  public initPayload() {
    let filter = this.filter();
    let constant = this.filterConstantValue;
    return Object.keys(filter).map(key => {
      if ([constant.gender, constant.category].includes(key)) {
        return `filter[${constant.category}]=${this.globalValue.navOption()},${filter[constant.category]}`;
      }
      else if (constant.sort === key && !!filter[key]) {
        let value = filter[key]?.replace(/['"]*/g, '').split(" ").join("_");
        return `sort=${value}`
      }
      else {
        return `filter[${key}]=${filter[key]}`
      }
    }).join('&');
  }

  public addQueryParamsAndRedirect(filter: CustomProductFilter) {
    const payload = Object.assign({},filter);
    for(let key in payload) {
      if(!payload[key]) {
        delete payload[key];
      }
    }
      this.router.navigate(['products'],{queryParams: payload});
  }
}
