import { Routes } from '@angular/router';
import {AccountPageComponent, LandingPageComponent} from "./pages";
import {UserPageComponent} from "./pages/user-page/user-page.component";
import {
  CartComponent,
  LoginComponent,
  ProductDetailComponent,
  RegisterComponent,
  CheckoutComponent,
  SaleComponent,
  ProductFilterComponent, NotFoundComponent,
  AccountWelcomeComponent,
  OopsComponent,
  AccountDetailsComponent,
  AccountPasswordComponent,
  AccountOrderComponent,
  AccountReturnComponent,
  AccountAddressComponent,
  AccountAddressAddComponent,
  PrivacyPolicyComponent,
  HomeV2Component
} from "./components";
import {authGuard} from "./shared/auth-guards/auth.guard";
import {HomeComponent} from "./pages/home/home.component";

export const routes: Routes = [
  {path:"", component: LandingPageComponent, title: 'Aura',
    children: [
      {path:"", component: HomeComponent},
      {path:"privacy-policy", component: PrivacyPolicyComponent  , title:'Privacy Policy'},
      // {path:"saved", component: CartComponent  , title:'Saved Items'},
      {path:"cart", component: CheckoutComponent  , title:'Checkout'},
      {path:"detail/:id", component: ProductDetailComponent  , title:'Product Detail'},
      // {path:"sale", component: SaleComponent  , title:'Product Detail'},
      {path:"products", component: ProductFilterComponent  , title:'Product Filter'},
      {path:"v2", component: HomeV2Component  , title:'Home V2'},
    ]
  },
  {path: "user", component: UserPageComponent,
    children: [
      {path:"", redirectTo: 'signin', pathMatch:'full'},
      {path:"signin", component: LoginComponent, title:'Sign In'},
      {path:"signup", component: RegisterComponent, title:'Sign Up'},
    ]
  },
  {
    path: "account" , component: AccountPageComponent,
    children: [
      {path:"", component: AccountWelcomeComponent, title:'Welcome'},
      // {path:"orders", component: AccountOrderComponent, title:'My Orders'},
      // {path:"returns", component: AccountReturnComponent, title:'My Returns'},
      {path:"my-details", component: AccountDetailsComponent, title:'My Details'},
      // {path:"address", component: AccountAddressComponent, title:'My Address'},
      // {path:"address/add", component: AccountAddressAddComponent, title:'Add Address'},
      {path:"change-password", component: AccountPasswordComponent, title:'Change Password'},
      {path:"**", component: OopsComponent, title:'Opps'}
    ],
    canActivate: [authGuard],

  },
  {path: "**",component: NotFoundComponent},
];
