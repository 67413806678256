<app-dynamic-video-player [VideoPlayerData]="videoPlayerData" />
<div class="content">
  <h2>
    MAKE SURE YOUR FAVES <br> DON’T MISS OUT!
  </h2>
  <p>
    CHECK OUR LAST-CHANCE DELIVERY DATES NOW
  </p>
</div>
<app-dynamic-video-player [VideoPlayerData]="videoPlayerData2" />
<app-dynamic-video-player [VideoPlayerData]="videoPlayerData2" />
<app-dynamic-video-player [VideoPlayerData]="videoPlayerData" />
<div class="content-two">
  <p>
    Ramesh is wearing Soft Polo Collar Jumper, Masculine <br>
    Pants, Flat leather shoes & Maxi Bucket Bag.
  </p>
</div>

<div class="dual__video__container">
  <app-dynamic-video-player [VideoPlayerData]="videoPlayerData2" />
  <app-dynamic-video-player [VideoPlayerData]="videoPlayerData2" />
</div>
