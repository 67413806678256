import { Component } from '@angular/core';
import {
  DynamicVideoPlayerComponent,
} from "../../components";
@Component({
  selector: 'app-home-v2',
  standalone: true,
  imports: [DynamicVideoPlayerComponent],
  templateUrl: './home-v2.component.html',
  styleUrl: './home-v2.component.scss'
})
export class HomeV2Component {
  protected videoPlayerData = {
    thumbnail:"",
    videoSrc:"assets/media/fake-video-one.mp4",
    isLandscape: true
  }
  protected videoPlayerData2 = {
    thumbnail:"",
    videoSrc:"assets/media/fake-video-two.mp4",
    isLandscape: false
  }
}
