import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {BrandsType} from "../../shared/model";
import {CommonModule, isPlatformBrowser, isPlatformServer} from "@angular/common";
import {ApiConstant} from "../../shared/constants";

@Component({
  selector: 'app-brands',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './brands.component.html',
  styleUrl: './brands.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BrandsComponent extends BaseComponentService{
  protected brandsArray: BrandsType[] = [];
  protected breakpoints = {
    // Default setting for all screen sizes
    525: {
      slidesPerView: 2, // 2 slides for screen widths > 640px
      spaceBetween: 10, // space between slides
    },
    640: {
      slidesPerView: 3, // 3 slides for screen widths > 1024px
      spaceBetween: 20, // space between slides
    },
    768: {
      slidesPerView: 4, // 4 slides for screen widths > 1440px

    },
    991: {
      slidesPerView: 5, // 4 slides for screen widths > 1440px

    },
    1024: {
      slidesPerView: 6, // 4 slides for screen widths > 1440px
      spaceBetween: 30,
    }
  };

   ngOnInit() {
     if(isPlatformBrowser(this.platformId)) {
       this.getBrands();
     }
  }

  private getBrands(){
     this.subscriptionArray = this.request.getRequest<BrandsType[]>(ApiConstant.BRANDS, {
       ...this.request.defaultConfiguration,
       auth: false,
       domain: this.request.secondBaseUrl
     }).subscribe({
       next: (res) => {
         if(res) {
           this.brandsArray = res.data;
           this.globalValue
             .setSearchFilterItems(['name'],
               this.brandsArray,'name','brand','logo');
         }
       }
     })
  }
}
