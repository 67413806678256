import {Component, computed, effect, inject, makeStateKey, StateKey} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {CommonModule, isPlatformBrowser, isPlatformServer} from "@angular/common";
import {RouterLink} from "@angular/router";
import {ApiConstant, COLLECTION} from "../../shared/constants";
import {CollectionService} from "../../shared/services/component-service/collection/collection.service";
import {Collection, SearchFilterItemsModel} from "../../shared/model";
import {DataIndex} from "../../shared/class/data-index.class";
import {FormsModule} from "@angular/forms";
import {OutsideClickDirective} from "../../shared/Directive/outside-click.directive";


@Component({
  selector: 'app-top-navbar',
  standalone: true,
  imports: [CommonModule, RouterLink, FormsModule, OutsideClickDirective],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss'
})
export class TopNavbarComponent extends BaseComponentService{
   protected collectionConst = COLLECTION;
   protected femaleFlag = computed(() => {
       if(this.globalValue.navOption()) {
         this.subTwoNavigationList = new DataIndex(this.subOneNavigationList.dict[this.globalValue.navOption()]?.children || []
           ,'attribute_data.name');
         this.globalValue
           .setSearchFilterItems(['attribute_data.name','attribute_data.description'],
             this.subTwoNavigationList
               .list,'attribute_data.name','category','attribute_data.thumbnail');
       }

       return this.globalValue.navOption() === COLLECTION.women;
   });
   public collectionService: CollectionService = inject(CollectionService);
   public collectionTreeKeyState: StateKey<Collection[]> = makeStateKey('collection-tree');
   public collectionTree: Collection[] = [];
   public subOneNavigationList: DataIndex = new DataIndex<Collection>();
   public subTwoNavigationList: DataIndex = new DataIndex<Collection>();

  public cart = this.cartService.cart;

  public searchItems: SearchFilterItemsModel[]  = [];

  private debounceTimer: NodeJS.Timeout | null = null;

  protected overlayFlag: boolean= false;



   protected dropdown = {
     subMenu: false,
     barMenu: false
   }

constructor() {
  super();
  effect(() => {
      this.searchList(this.globalValue.searchValue())
  })
}


   ngOnInit() {
     if(isPlatformServer(this.platformId)){
       this.getAllCollectionList();
     } else if(isPlatformBrowser(this.platformId)) {
       this.parseCollectionData(this.transferState.get(this.collectionTreeKeyState,null));
     }

  }

  private getAllCollectionList() {
     this.subscriptionArray = this.request
       .getRequest<Collection[]>(ApiConstant.COLLECTION,
         {...this.request.defaultConfiguration
           ,auth:false})
       .subscribe({
         next: (res) => {
           this.transferState.set(this.collectionTreeKeyState, res.data);
         }
       })
  }

  private parseCollectionData(res: Collection[] | null) {
     if(!res) return;
    this.collectionTree = this.collectionService.buildCollectionTree(res);
    this.subOneNavigationList = new DataIndex<Collection>(this.collectionTree, 'attribute_data.name');
    const flattenCollectionTree = this.collectionService.flattenCollectionTree(this.collectionTree);
  }


  private searchList(searchTerm: string) {
    this.debounceTimer && clearTimeout(this.debounceTimer);
    if(!(searchTerm.trim().length > 0)) {
      this.searchItems = [];
      return
    }
    this.debounceTimer = setTimeout(() => {
      if(this.globalValue.searchFilterItems) {
        this.searchItems = this.globalValue.searchFilterItems.list.filter(data => {
          return data.key.includes(searchTerm.trim().toLowerCase());
        })
      }
      if(searchTerm && this.searchItems.length === 0) {
        this.getProduct(searchTerm);
      }
    }, 300);
  }

  private getProduct(searchTerm: string) {
    this.subscriptionArray = this.request.getRequest(`${ApiConstant.PRODUCTS}?searchTerm=${searchTerm}`,{
      ...this.request.defaultConfiguration,
      auth: false
    }) .subscribe({
      next: res => {
        if(res) {
          let pro = res.data
          this.globalValue
            .setSearchFilterItems(['name','brand'],
              pro,'name','product','thumbnail');
        }
      }
    })
  }

  public searchClickNavigate(item: SearchFilterItemsModel) {
    this.globalValue.searchValue.set('');
    this.filterService.filter.set({});
     switch(item.type) {
       case 'category':
         this.filterService.setValue(this.filterService.filterConstantValue.category, item.name, true);
         break;
       case 'product':
         this.router.navigate(['/','detail',item?.id]);
         break;
       case 'brand':
         this.filterService.setValue(this.filterService.filterConstantValue.brand, item.name, true);
         break;
       default:
         break;
     }

  }

}

