import {effect, inject, Injectable, OnDestroy, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {ApiConstant, COLLECTION} from "../../constants";
import {isPlatformBrowser} from "@angular/common";
import {SearchFilterItemsModel, SearchFilterModel} from "../../model";
import {getNestedObjectValue, removeHTMLAndSpecialCharacter} from "../../function";

@Injectable({
  providedIn: 'root'
})
export class GlobalValueService {
  public navOption: WritableSignal<string> = signal('');
  public searchValue: WritableSignal<string> = signal("");
  public searchFilterItems: SearchFilterModel | null  = null;

  public platformId = inject(PLATFORM_ID);
  constructor() {
      effect(() => {
        const value = this.navOption();
        if(isPlatformBrowser(this.platformId) && value) {
         this.saveToLocalStorage();
        }
      })
    if(isPlatformBrowser(this.platformId)) {
      this.getFromLocalStorage();
    }
  }

  ngOnInit() {

  }
  private getFromLocalStorage() {
    try {
     const gender = localStorage.getItem(ApiConstant.LOCALSTORAGE.gender);
     this.navOption.set(gender || COLLECTION.men);
    } catch  {
      this.navOption.set(COLLECTION.men);
    }

  }

  public setSearchFilterItems<T>(keyFrom: Array<string>, list: Array<T>,title:string, type: string, image: string | null = null, filterString: string | null = null) {
      let prevList = this.searchFilterItems;
      let newList: Array<SearchFilterItemsModel> = [...(prevList?.list ?? [])];
      for(let li of list) {
        let key = '';
        let tempTitle =  getNestedObjectValue(title, li);
        let tempImage = getNestedObjectValue(image, li);
        for(let k of keyFrom) {
          let val = (getNestedObjectValue(k, li) ?? '').toLowerCase().trim();
          key = key.concat(`${removeHTMLAndSpecialCharacter(val)}|`);
        }
        if(!newList.map(data => data.key).includes(key)) {
          newList.push({
            key,
            name: tempTitle,
            type: type,
            thumbnail: tempImage,
            filterString: null,
            id:  getNestedObjectValue('id', li)
          })
        }
      }
      this.searchFilterItems = {
      list: newList,
      filteredList: prevList?.filteredList ?? []
    };
  }

  private saveToLocalStorage() {
    localStorage.setItem(ApiConstant.LOCALSTORAGE.gender, this.navOption());
  }



}
